<!-- 我的 -->
<template>
  <div class="user">
    <div class="top">
      <div class="device d-flex" @click="to_device()" v-if="charger_len > 0">
        <p class="icon"><i class="iconfont icon-chongdianzhong"></i></p>
        <span>正在充电</span>
      </div>
      <div class="info d-flex">
        <img
          class="head"
          :src="
            info.avatar ||
            'https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202106/25/Lark20210628-173948.png'
          "
        />
        <div>
          <p class="name">{{ info.nickname || "暂无昵称" }}</p>
          <p class="id" v-if="info.phone">{{ info.phone }}</p>
        </div>
      </div>
      <ul class="list">
        <li>
          <p class="num">
            <span>{{ charge_num || 0 }}</span
            >次
          </p>
          <p class="txt">充电次数</p>
        </li>
        <li>
          <p class="num">
            <span>{{ charge_time || 0 }}</span
            >小时
          </p>
          <p class="txt">累计时长</p>
        </li>
        <li>
          <p class="num">
            <span>{{ user_cards || 0 }}</span
            >张
          </p>
          <p class="txt">IC卡数</p>
        </li>
      </ul>
      <div class="money-box">
        <div class="money">
          <p class="over">
            账户余额：<span>￥{{ info.balance || 0 }}</span>
          </p>
          <button @click="to('recharge')">去充值</button>
        </div>
      </div>
    </div>
    <ul class="menu">
      <li @click="to('ic')">
        <i class="iconfont t-icon-icka"></i><span>我的IC卡</span>
      </li>
      <li @click="to('power_recode')">
        <i class="iconfont t-icon-chongdianjilu"></i><span>充电记录</span>
      </li>
      <li @click="to('deal')">
        <i class="iconfont t-icon-yonghuxieyi"></i><span>用户协议</span>
      </li>
    </ul>
    <tabbar :active="active"></tabbar>
  </div>
</template>

<script>
import { getData } from "@/api/user";
import tabbar from "@/components/tabbar.vue";
import store from "@/store";

export default {
  components: {
    tabbar,
  },
  data() {
    return {
      active: "user",
      info: {},
      user_cards: 0,
      charge_time: 0,
      charge_num: 0,
      charger_len: 0,
    };
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f7f7f7");
  },
  created() {
    this.get_info();
    this.get_charger();

    let userinfo = store.state.app;
    console.log("userinfo>>", userinfo);
  },
  methods: {
    // 用户信息
    get_info() {
      getData({
        url: "api/charging/user/user_info",
        data: {},
      }).then((res) => {
        if (res.data.code === 200) {
          var data = res.data.data.data;
          this.info = data.user_info || {};
          this.user_cards = data.user_cards;
          this.charge_time = data.charge_time;
          this.charge_num = data.charge_num;
        } else if (res.data.code === 422) {
          this.$toast(res.data.data.msg);
        }
      });
    },
    // 正在充电
    get_charger() {
      getData({
        url: "api/charging/index/current_user_charging",
        data: {},
      }).then((res) => {
        var data = res.data.data;
        if (res.data.code === 200) {
          this.charger_len = data.consume_ids.length;
          this.charger_id = data.consume_ids;
        } else if (res.data.code === 422) {
          this.$toast(res.data.data.msg);
        }
      });
    },
    to_device() {
      if (this.charger_len == 1) {
        this.$router.push({ name: "power", query: { id: this.charger_id[0] } });
      } else {
        this.$router.push({ name: "device" });
      }
    },
    to(path) {
      this.$router.push({ name: path });
    },
  },
  beforeRouteEnter(to, from, next) {
    var u = navigator.userAgent;

    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

    // XXX: 修复iOS版微信HTML5 History兼容性问题

    if (isiOS && to.path !== location.pathname) {
      // 此处不可使用location.replace

      location.assign(to.fullPath);
    } else {
      next();
    }
  },
};
</script>

<style lang="less">
.user {
  .top {
    position: relative;
    background: url(https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/%E7%BB%84%20883%403x.png)
      no-repeat top left;
    background-size: 100%;
    padding: 20px 12px 0;
    color: #fff;
    width: 100%;
    height: 233px;
    margin-bottom: 45px;
  }
  .device {
    align-items: center;
    position: absolute;
    top: 38px;
    right: 0;
    width: 97px;
    height: 36px;
    background: #2cb17b;
    border-radius: 18px 0 0 18px;
    font-size: 12px;
    font-weight: 600;
    line-height: 36px;
    color: #fff;
    padding: 5px 8px 5px 4px;
    .icon {
      width: 27px;
      height: 27px;
      background: #ffae15;
      border-radius: 50%;
      line-height: 27px;
      text-align: center;
      margin-right: 5px;
    }
    .iconfont {
      font-weight: 400;
      font-size: 18px;
    }
  }
  .info {
    padding-left: 14px;
    align-items: center;
    .head {
      height: 72px;
      width: 72px;
      border-radius: 50%;
      margin-right: 13px;
    }
    .name {
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;
      color: #fff;
    }
    .id {
      font-size: 14px;
      line-height: 20px;
      color: #fff;
    }
  }
  .menu {
    background: #fff;
    li {
      display: flex;
      align-items: center;
      height: 50px;
      line-height: 50px;
      padding: 0 24px;
      border-bottom: 1px solid #f5f5f7;
      font-size: 14px;
      color: #333;
      &:last-child {
        border: none;
      }
      .iconfont {
        font-size: 24px;
        height: 24px;
        width: 24px;
        margin-right: 10px;
      }
    }
  }
  .money-box {
    position: absolute;
    bottom: -30px;
    left: 0;
    width: 100%;
    padding: 0 12px;
  }
  .money {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 29px 24px;
    height: 80px;
    background: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    button {
      width: 70px;
      height: 26px;
      border: 1px solid #ffa42e;
      border-radius: 13px;
      font-size: 14px;
      line-height: 26px;
      color: #ffae15;
      background: #fff;
    }
  }
  .over {
    font-size: 14px;
    line-height: 22px;
    color: #333;
    span {
      font-size: 16px;
      font-weight: 600;
      color: #ffae15;
    }
  }
  .list {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 30px;
    li {
      text-align: center;
    }
    .num {
      font-size: 10px;
      line-height: 14px;
      span {
        font-size: 18px;
        font-weight: bold;
        line-height: 21px;
        margin-right: 4px;
      }
    }
    .txt {
      margin-top: 10px;
      font-size: 13px;
      line-height: 18px;
    }
  }
}
</style>
